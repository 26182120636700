import styled from "styled-components";
import { theme } from "../../../../theme/theme";

export const FeedBackWrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 100px 0;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0),
    rgba(239, 247, 244, 1)
  );
`;

export const FeedBackContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 1500px;
  flex-direction: column;
  padding: 0rem 4rem 3rem 4rem;
  @media (max-width: 850px) {
    padding: 0 1rem 2rem 1rem;
  }
`;

export const FeedBackContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-top: 60px;
  @media (max-width: 850px) {
    margin-top: 40px;
  }
`;

export const FeedBacksSection = styled.div`
  width: 80%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const FeedBackCard = styled.div`
  width: 100%;
  position: relative;
  background-image: url("/assets/images/review_bg.png");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 313px;
  padding: 3rem;
  border-radius: 35px;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 800px) {
    background-image: url("/assets/images/review_bg_mobile.png");
    padding: 1.5rem;
  }
`;

export const ArrowContainer = styled.div`
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.primary};
  border-radius: 100%;
  cursor: pointer;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-top: 1rem;
`;

export const ArrowContainerMobile = styled.div`
  display: none;
  @media (max-width: 800px) {
    width: 39px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${theme.colors.primary};
    border-radius: 100%;
    cursor: pointer;
  }
`;
