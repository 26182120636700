import styled from "styled-components";

export const Wrapper = styled.section`
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.9)
    ),
    url("/assets/images/career_cta.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 550px;

  @media (max-width: 800px) {
    height: 450px;
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.9)
    ),
    url("/assets/images/career_cta_mobile.svg");
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 6rem 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  @media (max-width: 800px) {
    padding: 6rem 1.5rem;

  }
`;

export const BtnCon = styled.div`
    margin: 2rem 0 1rem 0;
`;
