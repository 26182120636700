export class AppImages {
  static heroBgImg = "/assets/images/couple.png";
  static exploreBg = "/assets/images/explore_bg.png";
  static proto1 = "/assets/images/proto_display.png";
  static proto2 = "/assets/images/proto2.png";
  static proto3 = "/assets/images/proto3.png";
  static proto4 = "/assets/images/proto4.png";
  static whySpowse = "/assets/images/hold_hands.png";
  static aboutHero1 = "/assets/images/aboutHero.svg";
  static aboutMission = "/assets/images/about-mission.svg";
  static logoA = "/assets/images/logo1.png";
  static logoB = "/assets/images/logo2.png";
  static logoC = "/assets/images/logo3.png";
  static logoD = "/assets/images/logo4.png";
  static logoE = "/assets/images/logo5.png";
  static boost = "/assets/images/boost.svg";
  static premium = "/assets/images/premium.svg";
  static platinum = "/assets/images/platinum.svg";
  static contactHero = "/assets/images/contactHero.svg";
  static cupid = "/assets/images/cupid.svg";
  static postA = "/assets/images/post1.svg";
  static postB = "/assets/images/post2.svg";
  static teamwork = "/assets/images/Teamwork.svg";
  static safetyHero = "/assets/images/safety_hero.png";
  static safetyFeature = "/assets/images/safety_feature.png";
}
