import React, { useState } from "react";
import {
  ArrowContainer,
  ArrowContainerMobile,
  Box,
  FeedBackCard,
  FeedBackContainer,
  FeedBackContent,
  FeedBackWrapper,
  FeedBacksSection,
} from "./FeedBack_styles";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import {
  CommonText,
  Heading1,
  Heading2,
  Heading3,
} from "../../../../core/common/reuseables/text_styles";
import { theme } from "../../../../theme/theme";
import { feedBacks } from "./data/data";
import { SpowseImage } from "../../../../core/common/reuseables/spowse_image";
import { AppIcons } from "../../../../core/utils/app_icons";

type FeedBackProps = {};

const FeedBack: React.FC<FeedBackProps> = () => {
  const [swiper, setSwiper] = useState<any>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const setFeedbbackSwiper = (e: any) => {
    setSwiper(e);
  };

  return (
    <FeedBackWrapper>
      <FeedBackContent>
        <Heading3
          fontSize="16px"
          fontWeight="800"
          color={`${theme.colors.secondary}`}
          lineHeight="19.92px"
          fs="12px"
          textAlign="center"
        >
          WONDERING WHAT THE WORK ENVIRONMENT FEELS LIKE ?
        </Heading3>
        <Heading1
          fontSize="50px"
          fontWeight="800"
          color={`${theme.colors.primary}`}
          lineHeight="63px"
          letterSpacing="0.8px"
          fs="28px"
          lh="35px"
          textAlign="center"
          margin="0.5rem 0 1rem"
        >
          Explore Testimonials From The Spowse Team
        </Heading1>
        <CommonText
          fontSize="16px"
          fontWeight="400"
          color={`${theme.colors.primary}`}
          lineHeight="19.2px"
          fontFamily="geist-regular"
          textAlign="center"
          fs="14px"
          letterSpacing="0.2px"
        >
          Get the inside scoop! Read what our team says about working at Spowse
        </CommonText>
        <FeedBackContainer>
          <ArrowContainer
            onClick={() => {
              if (activeIndex !== 0) {
                setActiveIndex((prev: number) => {
                  const newIndex = prev - 1;
                  swiper.slideTo(newIndex);
                  return newIndex;
                });
              }
            }}
          >
            <SpowseImage
              src={AppIcons.leftArrow}
              alt="icon"
              width="auto"
              height="auto"
            />
          </ArrowContainer>
          <FeedBacksSection>
            <Swiper
              spaceBetween={30}
              slidesPerView={1}
              onActiveIndexChange={(e: any) => {
                setActiveIndex(e.activeIndex);
              }}
              onSwiper={setFeedbbackSwiper}
            >
              {feedBacks.map((feedBack, index) => {
                return (
                  <SwiperSlide key={index}>
                    <FeedBackCard>
                      <CommonText
                        fontSize="24px"
                        fontWeight="400"
                        color={`${theme.colors.white}`}
                        lineHeight="30px"
                        fs="18px"
                        lh="23px"
                        textAlign="center"
                        letterSpacing="0.16px"
                      >
                        {feedBack.feedBack}
                      </CommonText>
                      <Heading2
                        fontSize="24px"
           fontWeight="800"
                        color={`${theme.colors.white}`}
                        lineHeight="30px"
                        fontFamily='"Kumbh Sans", sans-serif'
                        letterSpacing="0.71px"
                        fs="18px"
                        margin="12px 0 0 0"
                        lh="23px"
                      >
                        {feedBack.name}
                      </Heading2>
                    </FeedBackCard>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </FeedBacksSection>
          <ArrowContainer
            onClick={() => {
              if (feedBacks.length - 1 !== activeIndex) {
                setActiveIndex((prev: number) => {
                  const newIndex = prev + 1;
                  swiper.slideTo(newIndex);
                  return newIndex;
                });
              }
            }}
          >
            <SpowseImage
              src={AppIcons.rightArrow}
              alt="icon"
              width="auto"
              height="auto"
            />
          </ArrowContainer>
        </FeedBackContainer>
        <Box>
          <ArrowContainerMobile
            onClick={() => {
              if (activeIndex !== 0) {
                setActiveIndex((prev: number) => {
                  const newIndex = prev - 1;
                  swiper.slideTo(newIndex);
                  return newIndex;
                });
              }
            }}
          >
            <SpowseImage
              src={AppIcons.leftArrow}
              alt="icon"
              width="auto"
              height="auto"
            />
          </ArrowContainerMobile>
          <ArrowContainerMobile
            onClick={() => {
              if (feedBacks.length - 1 !== activeIndex) {
                setActiveIndex((prev: number) => {
                  const newIndex = prev + 1;
                  swiper.slideTo(newIndex);
                  return newIndex;
                });
              }
            }}
          >
            <SpowseImage
              src={AppIcons.rightArrow}
              alt="icon"
              width="auto"
              height="auto"
            />
          </ArrowContainerMobile>
        </Box>
      </FeedBackContent>
    </FeedBackWrapper>
  );
};
export default FeedBack;
