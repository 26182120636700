import styled from "styled-components";
import { theme } from "../../../../theme/theme";

export const Wrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const HeroContent = styled.div`
  width: 100%;
  max-width: 1500px;
`;

export const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem;
  @media (max-width: 1053px) {
    flex-direction: column;
    justify-content: center;
    gap: 55px;
  }
  @media (max-width: 800px) {
    padding:1rem 1.5rem 55px 1.5rem;
  } 
`;

export const Left = styled.div`
  width: 700px;
  @media (max-width: 1130px) {
    width: 500px;
  }
  @media (max-width: 1053px) {
    width: 100%;
  }
`;

export const Right = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1053px) {
    width: 100%;
  }
`;

export const SmallText = styled.h4`
  font-weight: 800;
  font-size: 16px;
  color: ${theme.colors.secondary};
  letter-spacing: 0.8px;
  font-family: "Kumbh Sans", sans-serif;
  @media (max-width: 800px) {
    font-size: 12px;
  }
`;

export const Heading = styled.h1`
  font-weight: 800;
  color: ${theme.colors.primary};
  font-size: 60px;
  line-height: 75px;
  margin: 8px 0 16px 0;
  letter-spacing: 0.8px;
  font-family: "Kumbh Sans", sans-serif;
  @media (max-width: 1130px) {
    font-size: 50px;
    line-height: 60px;
  }
  @media (max-width: 800px) {
    font-size: 30px;
    line-height: 42px;
    margin: 4px 0 8px 0;

  }
`;

export const Span = styled.span`
  color: ${theme.colors.secondary};
  letter-spacing: 0.8px;
  font-family: "Kumbh Sans", sans-serif;
`;

export const Desc = styled.p`
  color: #282d2b;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 600px;
  font-family: "geist-light";
  letter-spacing: 0.8px;
  @media (max-width: 1130px) {
    width: 500px;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const Btn = styled.button`
  border-radius: 30px;
  background-color: ${theme.colors.secondary};
  color: ${theme.colors.white};
  padding: 15px 20px;
  font-weight: 400;
  font-size: 16px;
  margin: 2rem 0;
  border: none;
  font-family: "geist-regular";
  letter-spacing: 0.8px;
  @media (max-width: 800px) {
    margin: 1rem 0;

  }
`;
