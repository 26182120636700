import { motion } from "framer-motion";
import styled from "styled-components";
import { theme } from "../../../../theme/theme";

interface HeroProbs {
  backgroundImage?: any;
  bgImage?: any;
  tabletImg?: any;
}

export const HeroWrapper = styled.div`
  width: 100%;
  /* display: flex;
  align-items: center;
  justify-content: center; */
`;

export const HeroContent = styled(motion.div)<HeroProbs>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 1000px;
  max-height: 910px;
  margin: 0 auto;
  @media (max-width: 700px) {
    height: 722px;
    max-height: 722px;
  }
`;

export const ImageSection = styled(motion.div)<HeroProbs>`
  width: 100%;
  display: flex;
  position: absolute;
  background-image: ${(probs) => probs.backgroundImage};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  @media (max-width: 1140px) {
    background-image: ${(props) => props.tabletImg} ;
  }
  @media (max-width: 700px) {
    background-image: ${(props) => props.bgImage};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
`;

//!fade in text container

export const FadeInTextContainer = styled.div`
  text-align: center;
  position: absolute;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 200;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(16, 40, 30, 0) 0%,
    rgba(16, 40, 30, 0.4) 80%,
    rgba(16, 40, 30, 0.5) 100%
  );
`;

export const MiddleContext = styled.div`
  width: 70%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > :nth-child(3) {
    margin-top: 0.8rem;
  }
  @media (max-width: 1140px) {
    width: 90%;

  }
`;  

export const PaginationContainer = styled.div`
  position: absolute;
  height: 50px;
  width: 100%;
  bottom: 65px;
  z-index: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 53px;
    @media (max-width: 700px) {
    gap: 25px;
  }
`;

export const ActiveContainner = styled.div<{
  index?: number;
  activeIndex?: number;
}>`
  width: 135px;
  height: 8px;
  background: ${(probs) =>
    probs.index === probs.activeIndex ? theme.colors.white : "transparent"};
  border-radius: 4px;
  border: 1px solid white;
  @media (max-width: 700px) {
    width: 67px;
    height: 4px;

  }
`;
