import { styled } from "styled-components";

export const HeroSection = styled.div`
  padding: 6rem 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  @media (max-width: 960px) {
    flex-direction: column;
    padding: 1rem 4rem 6.25rem;
  }
  @media (max-width: 800px) {
    padding: 1rem 1.5rem 4.4rem;
  }
`;

export const Left = styled.div`
  flex: 1;
`;

export const Right = styled.div`
  flex: 1;
`;
