import React from "react";
import { Container, Wrapper } from "./Secuirity_styles";
import {
  CommonText,
  Heading1,
  Heading3,
} from "../../../../core/common/reuseables/text_styles";
import { theme } from "../../../../theme/theme";

const Secuirity = () => {
  return (
    <Wrapper>
      <Container>
        <Heading3
          color={`${theme.colors.white}`}
          fontWeight="800"
          fontSize="16px"
          lineHeight="20px"
          letterSpacing="0.2px"
          fs=" 12px"
          lh="15px"
          textAlign="center"
        >
          WHERE SECURITY MEETS CONNECTION
        </Heading3>
        <Heading1
          color={`${theme.colors.white}`}
          fontWeight="800"
          fontSize="50px"
          lineHeight="63px"
          letterSpacing="0.8px"
          fs="28px"
          lh="35px"
          margin="0.5rem 0 1rem"
          textAlign="center"
        >
          Why Security Is Core To The Spowse Experience
        </Heading1>
        <CommonText
          color={`${theme.colors.white}`}
          fontWeight="400"
          fontSize="16px"
          lineHeight="20px"
          letterSpacing="0.8px"
          textAlign="center"
          width="1050px"
          wid="100%"
          w="100%"
        >
          At the heart of Spowse is the belief that trust fosters genuine,
          lasting connections. Designed for African singles, our app creates a
          safe, secure, and inclusive community. We recognize the unique
          challenges of online dating today, making security the backbone of our
          platform. Spowse continually evolves to provide the best dating
          experience, allowing you to focus on what truly matters: forming
          meaningful connections.
        </CommonText>
      </Container>
    </Wrapper>
  );
};

export default Secuirity;
