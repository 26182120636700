import ApiService from "../api";
import { ApiUrl } from "../api_url";

interface BlogPost {
  title: string;
  category: string;
  publishDate: string;
  thumbnail: string;
  id: string;
}

interface BlogApiResponse {
  success: boolean;
  message: string;
  data: {
    blogs: BlogPost[];
    // lastPage: number | null;
  };
}

class BlogService {
  private apiService: ApiService;

  constructor() {
    this.apiService = new ApiService();
  }

  async getBlogsByCategory(category?: string): Promise<{
    success: boolean;
    blogs: BlogPost[] | null | any;
    message?: string;
  }> {
    const url = category
      ? `${ApiUrl.ALL_BLOGS}?category=${encodeURIComponent(category)}`
      : ApiUrl.ALL_BLOGS;
    const response = await this.apiService.getData<BlogApiResponse>(url);
    return {
      success: response.success,
      blogs: response.data?.data?.blogs || null,
      message: response.message || "No message provided",
    };
  }
}

export default new BlogService();
