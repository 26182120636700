import React, { useEffect, useState } from "react";
import { Container, ImgCon, SectionCon, Wrapper } from "./Content_styles";
import {
  CommonText,
  Heading1,
  Heading3,
  Heading4,
} from "../../../../core/common/reuseables/text_styles";
import { SpowseImage } from "../../../../core/common/reuseables/spowse_image";
import { theme } from "../../../../theme/theme";
import { useParams } from "react-router-dom";
import BlogPostService from "../../../../core/api/services/blog_post";
import { formatDate } from "../../../../utilis/date";
import Loader from "../../../loader/Loader";

interface BlogPost {
  title: string;
  category: string;
  coverImage: string;
  publishDate: string;
  subTitle: string;
  sections: {
    heading: string;
    content: string;
    image?: string;
  }[];
}

const Content = () => {
  const { id } = useParams<{ id: string }>();

  const [blog, setBlog] = useState<BlogPost | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBlogPost = async () => {
      if (id) {
        const response = await BlogPostService.getBlogPostById(id);
        if (response.success && response.blog) {
          setBlog(response.blog);
        } else {
          setError(response.message || "Failed to load blog post");
        }
        setLoading(false);
      }
    };

    fetchBlogPost();
  }, [id]);

  if (loading) {
    return <Loader/>;
  }

  // if (error) {
  //   return <p>Error: {error}</p>;
  // }

  return (
    <Wrapper>
      {blog && (
        <Container>
          <Heading3
            color={`${theme.colors.secondary}`}
            fontSize="16px"
            fontWeight="800"
            fs="12px"
            lh="15px"
            lineHeight="20px"
            letterSpacing="0.8px"
          >
            {blog?.category}
          </Heading3>
          <Heading1
            color={`${theme.colors.primary}`}
            fontSize="60px"
            fontWeight="800"
            fs="30px"
            lh="38px"
            lineHeight="75px"
            letterSpacing="0.8px"
            margin="0.5rem 0 1rem 0"
            m="0.25rem 0 0.5rem 0"
          >
            {blog?.title}
          </Heading1>
          <ImgCon>
            <SpowseImage src={blog?.coverImage} alt="img" />
          </ImgCon>
          <CommonText
            margin="1.5rem 0"
            fontSize="14px"
            lineHeight="17px"
            fs="14px"
            lh="17px"
            m="1rem 0"
          >
            {formatDate(blog.publishDate)}
          </CommonText>
          <CommonText margin="2rem 0 2.5rem" m="2rem 0">
            {blog?.subTitle}
          </CommonText>
          {blog?.sections.map((blog, index) => (
            <SectionCon key={index}>
              <Heading4 margin="2.5rem 0 1rem" m="2rem 0 0.75rem">
                {blog?.heading}
              </Heading4>
              <CommonText margin="0">{blog?.content}</CommonText>
              {blog?.image && (
                <ImgCon>
                  <SpowseImage src={blog?.image} margin="1.5rem 0 1.2rem" borderRadius="35px"/>
                </ImgCon>
              )}
            </SectionCon>
          ))}
        </Container>
      )}
    </Wrapper>
  );
};

export default Content;
