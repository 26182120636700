import React, { useEffect, useState } from "react";
import {
  TrybeSectionContent,
  TrybeSectionGridContainer,
  TrybeSectionGridContent,
  TrybeSectionWrapper,
} from "./trybe_section_styles";
import {
  CommonText,
  Heading1,
  Heading3,
} from "../../../../core/common/reuseables/text_styles";
import { theme } from "../../../../theme/theme";
import { homeTrybeSection } from "../../controllers/logic";
import { SpowseImage } from "../../../../core/common/reuseables/spowse_image";
import { SpowseButton } from "../../../../core/common/reuseables/spowse_button";
import { blogs } from "../../../trybe/data/data";
import { Link } from "react-router-dom";
import BlogService from "../../../../core/api/services/blog";
import ApiService from "../../../../core/api/api";
import Loader from "../../../loader/Loader";
interface BlogPost {
  title: string;
  category: string;
  publishDate: string;
  thumbnail: string;
  id: string;
}

const TrybeSection = () => {
  const [posts, setPosts] = useState<BlogPost[]>([]); // Default to an empty array
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const response = await BlogService.getBlogsByCategory(); // Fetch all blog posts
        const blogs = response?.blogs || []; // Safely handle the blogs property
        setPosts(blogs);
      } catch (err) {
        setError("Failed to load blog posts.");
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPosts();
  }, []);

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <TrybeSectionWrapper>
      <TrybeSectionContent>
        <Heading3
          fontSize="14px"
          fontWeight="800"
          color={`${theme.colors.secondary}`}
          lineHeight="19.92px"
          fs="14px"
        >
          DIVE DEEP WITH TRYBE
        </Heading3>
        <Heading1
          fontSize="50px"
          fontWeight="800"
          color={`${theme.colors.primary}`}
          lineHeight="63px"
          letterSpacing="0.8px"
          fs="28px"
          lh="35px"
          textAlign="center"
        >
          Your One Stop Destination For Exclusive Insights
        </Heading1>
        <CommonText
          fontSize="16px"
          fontWeight="400"
          color={`${theme.colors.primary}`}
          lineHeight="19.2px"
          fontFamily="geist-regular"
          textAlign="center"
          letterSpacing="0.2px"
        >
          Explore insightful articles uncovering Spowse and essential insights
          for fostering genuine relationships within the African community.
        </CommonText>
        {loading ? (
          <Loader />
        ) : (
          <TrybeSectionGridContainer>
            {posts.slice(0, 3).map((trybe, index) => {
              return (
                <Link
                  key={trybe.id} // Add a unique key for better performance
                  to={`/trybe/${trybe.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <TrybeSectionGridContent>
                    <SpowseImage src={trybe?.thumbnail} alt="img" />
                    <Heading3
                      color={`${theme.colors.secondary}`}
                      fontSize="14px"
                      fontWeight="800"
                      letterSpacing="0.8px"
                      lineHeight="17.43px"
                      fs="12px"
                    >
                      {trybe.category}
                    </Heading3>
                    <Heading3
                      color={`${theme.colors.primary}`}
                      fontSize="24px"
                      fontWeight="800"
                      letterSpacing="0.8px"
                      lineHeight="30px"
                      fs="20px"
                      lh="24px"
                    >
                      {trybe.title}
                    </Heading3>
                    <CommonText
                      color={`${theme.colors.primary}`}
                      fontSize="14px"
                      fontWeight="400"
                      letterSpacing="0.8px"
                      lineHeight="16.8px"
                      fontFamily="geist-regular"
                    >
                      26 May 2023
                    </CommonText>
                  </TrybeSectionGridContent>
                </Link>
              );
            })}
          </TrybeSectionGridContainer>
        )}
        <Link to="/trybe" style={{ textDecoration: "none" }}>
          <SpowseButton
            fontWeight="400"
            fontSize="16px"
            lineHeight="19.2px"
            fontFamily="geist-regular"
            width="193px"
            height="52px"
            m="0"
          >
            Read More
          </SpowseButton>
        </Link>
      </TrybeSectionContent>
    </TrybeSectionWrapper>
  );
};

export default TrybeSection;
