import {
  Wrapper,
  Container,
  Left,
  Right,
  BtnCon,
  FlexCon,
} from "./Life_styles";
import {
  CommonText,
  Heading1,
  Heading3,
  SpanText,
} from "../../../../core/common/reuseables/text_styles";
import { SpowseButton } from "../../../../core/common/reuseables/spowse_button";
import { theme } from "../../../../theme/theme";
import { Link } from "react-router-dom";

const Life = () => {
  return (
    <Wrapper>
      <Container>
        <FlexCon>
          <Left>
            <Heading3
              color={`${theme.colors.lightGreen}`}
              fontWeight="800"
              fontSize="16px"
              lineHeight="20px"
              letterSpacing="0.2px"
              fs=" 12px"
              lh="15px"
            >
              LIFE AT SPOWSE
            </Heading3>
            <Heading1
              color={`${theme.colors.white}`}
              fontWeight="800"
              fontSize="40px"
              lineHeight="50px"
              letterSpacing="0.8px"
              fs="24px"
              lh="30px"
              margin="12px 0"
              width="600px"
              wid="100%"
              w="100%"
            >
              Remotely Connecting Africa And Building Lasting Love Stories As A
              Team
            </Heading1>
          </Left>
          <Right>
            <CommonText
              color={`${theme.colors.white}`}
              fontWeight="400"
              fontSize="16px"
              lineHeight="22px"
              letterSpacing="0.8px"
            >
              Spowse is home to a diverse group of talented Africans who
              collaborate, work and grow together. We believe in fostering a
              culture of inclusion where{" "}
              <SpanText color="#5FBD80" fontWeight="400">
                everyone feels valued{" "}
              </SpanText>
              from day one.
            </CommonText>
            <CommonText
              color={`${theme.colors.white}`}
              fontWeight="400"
              fontSize="16px"
              lineHeight="22px"
              letterSpacing="0.8px"
              margin="16px 0"
            >
              At Spowse, you will harness your skills to create a positive
              impact for other Africans seeking love through our platform. We
              empower you to{" "}
              <SpanText color="#5FBD80" fontWeight="400">
                {" "}
                make a difference
              </SpanText>{" "}
              in the lives of others.
            </CommonText>
            <BtnCon>
              <Link
                to={`/career/submit-an-application`}
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <SpowseButton
                  textColor={`${theme.colors.primary}`}
                  backgroundColor={`${theme.colors.lightGreen}`}
                >
                  Apply Now
                </SpowseButton>
              </Link>
            </BtnCon>
          </Right>
        </FlexCon>
      </Container>
    </Wrapper>
  );
};

export default Life;
