import React from "react";
import {
  Container,
  Desc,
  Heading,
  Img,
  Left,
  Right,
  SmallText,
  Wrapper,
} from "./Mission_styles";
import { SpowseImage } from "../../../../core/common/reuseables/spowse_image";
import { AppImages } from "../../../../core/utils/app_images";

const Mission = () => {
  return (
    <Wrapper>
      <Container>
        <Left>
          <Img src={AppImages.aboutMission} alt="img" height="auto" />
        </Left>
        <Right>
          <SmallText>THE SPOWSE MISSION</SmallText>
          <Heading>Empowering African Connections That Last</Heading>
          <Desc>
            We know that dating today can feel overwhelming, with too many
            options and not enough meaningful connections. We believe dating
            should be a safe, inclusive and empowering experience, which is why
            Spowse is built to help you meet Africans who share your values,
            interests and goals.{" "}
          </Desc>
          <Desc> 
            We strive to revolutionize the dating experience for Africans
            through innovative features that empower you to have genuine
            interactions, spark real conversations, and promote personal growth.
          </Desc>
          <Desc>
            Our ultimate goal? To help Africans build fulfilling relationships
            that last and facilitate a happier and more connected world.
          </Desc>
        </Right>
      </Container>
    </Wrapper>
  );
};

export default Mission;
