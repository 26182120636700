export const feedBacks = [
  {
    feedBack:
      "“At Spowse, I have been challenged to push my creative boundaries and collaborate closely with engineers and marketers to bring innovative features to life. It is incredibly rewarding to see my designs used by thousands of Africans seeking love. Spowse truly feels like family and i am proud to be part of a team thats building something so meaningful”.",
    name: "Daisy Ohikhuare, Product Designer",
  },
  {
    feedBack:
      "Spowse has been a game-changer for me. The sign-up process was straightforward, and I liked the personality test that helps in finding compatible matches. The quality of profiles here is quite high compared to other dating sites I've used. I've had some great conversations and met a few interesting people.",
    name: "Michael Tithans",
  },
  {
    feedBack:
      "Spowse offers a refreshing approach to online dating. The platform feels secure and reliable, and I appreciate the verification process for profiles. It gives an added layer of trust that is often missing on other dating sites. The matching algorithm seems to work well – I’ve had several promising matches.",
    name: "Emily Ryhna",
  },
];
