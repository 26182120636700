export const footerLinks = [
  {
    topText: "About Us",
    links: [
      // {
      //   value: "Mission",
      //   to: "/",
      // },
      {
        value: "Careers",
        to: "/career",
      },
      // {
      //   value: "Press",
      //   to: "/",
      // },
      {
        value: "Contact Us",
        to: "/contact-us",
      },
    ],
  },
  {
    topText: "Legal",
    links: [
      // {
      //   value: "Security",
      //   to: "/",
      // },
      {
        value: "Privacy",
        to: "https://app.enzuzo.com/policies/privacy/c4c497a4-bba1-11ef-8628-d774d06ef34a",
      },
      {
        value: "Terms Of Service",
        to: "https://app.enzuzo.com/policies/tos/c4c497a4-bba1-11ef-8628-d774d06ef34a",
      },
      {
        value: "Cookies Policy",
        to: "https://app.enzuzo.com/policies/cookies/c4c497a4-bba1-11ef-8628-d774d06ef34a",
      },
    ],
  },
  {
    topText: "Resources",
    links: [
      {
        value: "FAQ",
        to: "/faqs",
      },
      {
        value: "Get Help",
        to: "/contact-us/help-and-support",
      },
      // {
      //   value: "Dating Advice",
      //   to: "/",
      // },
      {
        value: "Safety Tips",
        to: "/safety",
      }, 
      {
        value: "Community Guidelines",
        to: "/community-guidelines",
      },
    ],
  },
];
