export const safetyfeature = [
  {
    title: "Advanced Privacy Filters",
    desc: "Take control of your experience on Spowse. Our customizable filters allow you to decide who can interact with you based on location, interests, and other preferences, adding an extra layer of safety, comfort, and ease to your dating journey.",
  },
  {
    title: "Data Protection & Privacy",
    desc: "We adhere to global data protection standards. Your information is never shared without your consent, and you have full control over your profile and data settings. Whether you take a break or delete your account, we carefully handle your information.",
  },
  {
    title: "Verified Profiles",
    desc: "Our thorough verification process ensures every Spowse profile is genuine, reducing fake profiles, scammers, and bots. Whether connecting locally or across the continent, you can trust in verified interactions.",
  },
  {
    title: "End-To-End Encryption",
    desc: "Spowse uses end-to-end encryption to keep your chats and video calls secure. Only you and the person you’re chatting with can access your messages—Spowse included. Share videos, photos and chats with full peace of mind.",
  },
  {
    title: "Secure Video Calling",
    desc: "Connect face-to-face safely within Spowse. Our in-app video calls offer added security, keeping your interactions private without the need for external platforms or sharing contact details.",
  },
  {
    title: "Robust Reporting And Blocking Tools",
    desc: "Your security is a priority. If a user makes you uncomfortable, easily block or report them. Our team promptly reviews reports, supported by AI systems to detect and address inappropriate behavior swiftly, ensuring a safe space for all.",
  },
  {
    title: "Regular Security Updates",
    desc: "Stay Ahead. Spowse frequently rolls out security patches and updates to guard against evolving cyber threats, ensuring your data and interactions remain protected.",
  },
];
