// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.5s ease-in-out;
}

.main.open {
  z-index: 100;
  opacity: 1;
  display: block;
}
.main.close {
  opacity: 0;
  z-index: -100;
  transition: opacity 1s ease-in-out, z-index 4s;
  display: none;
}

.container {
  display: none;
}

@media (max-width: 800px) {
  .container {
    display: block;
    background-color: #ffffff;
    position: fixed;
    height: 100%;
    top: 0px;
    left: 0;
    width: 100%;
    transition: all 0.7s ease-in;
    z-index: 10000;
  }

  .container.open {
    transform: translateX(0vw);
    box-shadow: 26px 0px 80px -6px rgba(0, 0, 0, 1);
  }
  .container.close {
    transform: translateX(-100%);
    box-shadow: 0px 0px 0px -200px rgba(0, 0, 0, 0);
  }
}
`, "",{"version":3,"sources":["webpack://./src/presentations/sidebar/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,8BAA8B;EAC9B,oCAAoC;AACtC;;AAEA;EACE,YAAY;EACZ,UAAU;EACV,cAAc;AAChB;AACA;EACE,UAAU;EACV,aAAa;EACb,8CAA8C;EAC9C,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,cAAc;IACd,yBAAyB;IACzB,eAAe;IACf,YAAY;IACZ,QAAQ;IACR,OAAO;IACP,WAAW;IACX,4BAA4B;IAC5B,cAAc;EAChB;;EAEA;IACE,0BAA0B;IAG1B,+CAA+C;EACjD;EACA;IACE,4BAA4B;IAG5B,+CAA+C;EACjD;AACF","sourcesContent":[".main {\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  background: rgba(0, 0, 0, 0.5);\n  transition: opacity 0.5s ease-in-out;\n}\n\n.main.open {\n  z-index: 100;\n  opacity: 1;\n  display: block;\n}\n.main.close {\n  opacity: 0;\n  z-index: -100;\n  transition: opacity 1s ease-in-out, z-index 4s;\n  display: none;\n}\n\n.container {\n  display: none;\n}\n\n@media (max-width: 800px) {\n  .container {\n    display: block;\n    background-color: #ffffff;\n    position: fixed;\n    height: 100%;\n    top: 0px;\n    left: 0;\n    width: 100%;\n    transition: all 0.7s ease-in;\n    z-index: 10000;\n  }\n\n  .container.open {\n    transform: translateX(0vw);\n    -webkit-box-shadow: 26px 0px 80px -6px rgba(0, 0, 0, 1);\n    -moz-box-shadow: 26px 0px 80px -6px rgba(0, 0, 0, 1);\n    box-shadow: 26px 0px 80px -6px rgba(0, 0, 0, 1);\n  }\n  .container.close {\n    transform: translateX(-100%);\n    -webkit-box-shadow: 0px 0px 0px -200px rgba(0, 0, 0, 0);\n    -moz-box-shadow: 0px 0px 0px -200px rgba(0, 0, 0, 0);\n    box-shadow: 0px 0px 0px -200px rgba(0, 0, 0, 0);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
