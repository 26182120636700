import React, { useEffect, useState } from "react";
import { Btn, BtnCon, Dot, Dots, TextCon, Wrapper } from "./Hero_styles";
import { slider } from "./data/data";
import {
  CommonText,
  Heading1,
  Heading3,
} from "../../../../core/common/reuseables/text_styles";
import { GoDotFill } from "react-icons/go";

const Hero = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const goToNext = (index: number) => {
    setCurrentSlide(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slider.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Wrapper>
      <TextCon image={slider[currentSlide].url} imageMobile={slider[currentSlide].urlMobile}>
        <Heading3
          color="#f2f2f2"
          fontSize="16px"
          fontWeight="800"
          fs="12px"
          lh="15px"
          lineHeight="20px"
          letterSpacing="0.8px"
          textAlign="center"
        >
          {slider[currentSlide].category}
        </Heading3>
        <Heading1
          color="#ffffff"
          fontSize="60px"
          fontWeight="800"
          fs="30px"
          lh="38px"
          lineHeight="75px"
          letterSpacing="0.8px"
          textAlign="center"
          width="57%"
          w="100%"
          wid="70%"
          margin="1rem"
        >
          {slider[currentSlide].title}
        </Heading1>
        <CommonText
          color="#ffffff"
          fontSize="14px"
          fontWeight="400"
          lineHeight="17px"
          letterSpacing="0.8px"
          textAlign="center"
        >
          {slider[currentSlide].date}
        </CommonText>
        <BtnCon>
          <Btn>Start Reading</Btn>
        </BtnCon>
        <Dots>
          {slider.map((item, index) => (
            <Dot
              isActive={currentSlide === index}
              key={index}
              onClick={() => goToNext(index)}
            >
              <GoDotFill />
            </Dot>
          ))}
        </Dots>
      </TextCon>
    </Wrapper>
  );
};

export default Hero;
