export const slider = [
  {
    url: "https://res.cloudinary.com/dsjgyouib/image/upload/v1733345499/website%20images/r3wkcjepkgsh8ocgfzho.png",
    category: "PRODUCT UPDATE",
    title: "An Introduction To Online Dating For Africans",
    date: "26 May 2023",
    urlMobile: "https://res.cloudinary.com/dsjgyouib/image/upload/v1733345499/website%20images/jyrsyxuq024skmgog0t1.png"
  },
  {
    url: "https://res.cloudinary.com/dsjgyouib/image/upload/v1733345502/website%20images/zzruvzyb6lup3hgzfppk.png",
    category: "DATING TIPS",
    title: "An Introduction To Online Dating For Africans",
    date: "26 October 2024",
    urlMobile: "https://res.cloudinary.com/dsjgyouib/image/upload/v1733345500/website%20images/m8pc1kiqny8atzuk6zy3.png"
  },
  {
    url: "https://res.cloudinary.com/dsjgyouib/image/upload/v1733345500/website%20images/liw5ek8xfs8dggba2dll.png",
    category: "HOW-TOS",
    title: "Stand Out In The Dating World With These Few Tips",
    date: "26 May 2023",
    urlMobile: "https://res.cloudinary.com/dsjgyouib/image/upload/v1733345499/website%20images/arihj8bsewmfntaau9vh.png"
  },
];
