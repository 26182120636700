import styled from "styled-components";

export const Wrapper = styled.section`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 3rem 4rem;

  @media (max-width: 800px) {
    padding: 1rem 1.5rem;
    max-width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6rem;

  @media (max-width: 1150px) {
    gap: 4rem;
  }
  @media (max-width: 1050px) {
    gap: 0rem;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 90px;
  }
`;

export const Left = styled.div`
  flex: 1;
  width: 100%;
`;

export const Right = styled.div`
  flex: 1;
  width: 100%;
`;
