import styled from "styled-components";
import { theme } from "../../../../theme/theme";

export const Wrapper = styled.section``;

export const Container = styled.div`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 2rem 4rem;

  @media (max-width: 800px) {
    padding: 1.5rem 1.5rem 100px;
  }
`;

export const FlexCon = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  column-gap: 50px;
  row-gap: 40px;
  margin: 40px 0;
`;

export const Items = styled.div``;

export const ImgCon = styled.div`
 width: fit-content;
 margin: 0 auto;
`;
