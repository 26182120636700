import React from "react";
import { BtnCon, Container, Wrapper } from "./Cta_styles";
import {
  CommonText,
  Heading1,
  Heading3,
} from "../../../../core/common/reuseables/text_styles";
import { SpowseButton } from "../../../../core/common/reuseables/spowse_button";
import { theme } from "../../../../theme/theme";
import { Link } from "react-router-dom";

const Cta = () => {
  return (
    <Wrapper>
      <Container>
        <Heading3
          color={`${theme.colors.white}`}
          fontWeight="800"
          fontSize="16px"
          lineHeight="20px"
          letterSpacing="0.2px"
          fs=" 12px"
          lh="15px"
          textAlign="center"
        >
          ARE YOU READY TO MAKE A DIFFERENCE?
        </Heading3>
        <Heading1
          color={`${theme.colors.white}`}
          fontWeight="800"
          fontSize="50px"
          lineHeight="63px"
          letterSpacing="0.8px"
          fs="28px"
          lh="35px"
          margin="0.5rem 0 1rem"
          textAlign="center"
        >
          {" "}
          Join Our Passionate Team At Spowse
        </Heading1>
        <CommonText
          color={`${theme.colors.white}`}
          fontWeight="400"
          fontSize="16px"
          lineHeight="20px"
          letterSpacing="0.8px"
          textAlign="center"
          width="800px"
          w="100%"
        >
          Become a team member of Spowse and make an impact on the lives of
          Africans looking for that connection that feels like home.{" "}
        </CommonText>
        <BtnCon>
          <Link
            to={`/career/submit-an-application`}
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <SpowseButton
              textColor={`${theme.colors.primary}`}
              backgroundColor={`${theme.colors.white}`}
              width="193px"
            >
              Apply Now
            </SpowseButton>
          </Link>
        </BtnCon>
      </Container>
    </Wrapper>
  );
};

export default Cta;
