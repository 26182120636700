import React, { useState } from "react";
import {
  Column,
  Con,
  Feature,
  FeaturesList,
  GridContainer,
  GridItems,
  ImgCon,
  OverviewCon,
  OverviewMobileCon,
  Plan,
  PlanTitle,
  Row,
  Table,
  Tbody,
  Th,
  Thead,
} from "./TabContent_styles";
import { SpowseImage } from "../../../../core/common/reuseables/spowse_image";
import { overview, plans, plansOverwiew } from "../../data/data";
import {
  CommonText,
  Heading1,
  Heading3,
} from "../../../../core/common/reuseables/text_styles";
import { Divider } from "../../../../core/common/reuseables/Divider";
import { AppIcons } from "../../../../core/utils/app_icons";
import { theme } from "../../../../theme/theme";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import "../../product.css";

interface TabContentProps {
  currentTab: "weekly" | "monthly";
}

const TabContent: React.FC<TabContentProps> = ({ currentTab }) => {
  const [selected, setSelected] = useState(null);

  const toggle = (index: any) => {
    if (selected === index) {
      return setSelected(null);
    }
    setSelected(index);
  };

  return (
    <div>
      <GridContainer>
        {plans.map((plan, index) => (
          <GridItems
            key={index}
            style={{ backgroundColor: plan.backgroundColor }}
          >
            <ImgCon>
              <SpowseImage
                src={plan.image}
                alt="img"
                width="200px"
                height="200px"
              />
            </ImgCon>
            <Heading1
              fontSize="40px"
              fontWeight="800"
              lineHeight="50px"
              letterSpacing="0.8px"
              fs="24px"
              lh="30px"
              margin="0 1.5rem"
              color={`${theme.colors.primary}`}
            >
              {plan.title}
            </Heading1>
            <CommonText
              fontSize="16px"
              lineHeight="20px"
              letterSpacing="0.8px"
              fontWeight="400"
              margin="0.5rem 1.5rem 1.5rem 1.5rem"
              color="#282D2B"
            >
              {plan.desc}
            </CommonText>
            <CommonText
              fontSize="40px"
              lineHeight="50px"
              letterSpacing="0.8px"
              fontWeight="400"
              margin="0.5rem 1.5rem 0 1.5rem"
              color={`${theme.colors.primary}`}
              fs="24px"
              lh="30px"
            >
              {plan.prices[currentTab]}
            </CommonText>
            <CommonText
              fontSize="16px"
              lineHeight="20px"
              letterSpacing="0.8px"
              fontWeight="400"
              margin="0rem 1.5rem 1rem 1.5rem"
              color="#282D2B"
            >
              {currentTab === "weekly" ? "Per week" : "Per month"}
            </CommonText>
            <Divider
              width="100%"
              height="1px"
              backgroundColor="rgba(16, 40, 30, 0.2)"
              spacing="1.5rem 0 "
            />
            <Heading3
              fontSize="16px"
              lineHeight="20px"
              letterSpacing="0.8px"
              fontWeight="800"
              margin="0 1.5rem"
              color={`${theme.colors.primary}`}
            >
              {plan.keyFeature}
            </Heading3>
            <FeaturesList>
              {plan.features.map((feature, i) => (
                <Con>
                  <SpowseImage src={AppIcons.dot} alt="dot" width="8px" />
                  <Feature key={i}>{feature}</Feature>
                </Con>
              ))}
            </FeaturesList>
          </GridItems>
        ))}
      </GridContainer>
      <Heading1
        textAlign="center"
        fontSize="50px"
        lineHeight="63px"
        letterSpacing="0.8px"
        fontWeight="900"
        fs="28px"
        lh="35px"
        margin="6.25rem 0 3.125rem 0"
        m="3.125rem 0 1.5rem 0"
      >
        Overview
      </Heading1>
      <OverviewCon>
        <Table>
          <Thead>
            <Row>
              <Th style={{ textAlign: "left" }}>FEATURES</Th>
              <Th>FREE</Th>
              <Th>BOOST</Th>
              <Th>PREMIUM</Th>
              <Th>PLATINUM</Th>
            </Row>
          </Thead>
          <Tbody>
            {plansOverwiew.map((item, index) => (
              <Row key={index}>
                <Column style={{ width: "25%" }}>{item.features}</Column>
                <Column style={{ width: "18.75%", textAlign: "center" }}>
                  <SpowseImage src={item?.free} width="8px" />
                </Column>
                <Column style={{ width: "18.75%", textAlign: "center" }}>
                  <SpowseImage src={item?.boost} width="8px" />
                </Column>
                <Column style={{ width: "18.75%", textAlign: "center" }}>
                  {" "}
                  <SpowseImage src={item?.premium} width="8px" />
                </Column>
                <Column style={{ width: "18.75%", textAlign: "center" }}>
                  <SpowseImage src={item?.platinum} width="8px" />
                </Column>
              </Row>
            ))}
          </Tbody>
        </Table>
      </OverviewCon>
      <OverviewMobileCon>
        {overview.map((item, index) => (
          <Plan key={index}>
            <PlanTitle onClick={() => toggle(index)}>
              <Heading1
                margin="0"
                fontSize="21px"
                fs="18px"
                lh="23px"
                fontWeight="800"
                letterSpacing="0.8px"
              >
                {item.title}
              </Heading1>
              {selected === index ? (
                <IoIosArrowUp style={{ fontSize: "20px" }} />
              ) : (
                <IoIosArrowDown style={{ fontSize: "20px" }} />
              )}
            </PlanTitle>
            <div className={selected === index ? "show" : "hide"}>
              {item.features.map((feature, i) => (
                <Con>
                  <SpowseImage src={AppIcons.dot} alt="dot" width="8px" />
                  <Feature key={i}>{feature}</Feature>
                </Con>
              ))}
            </div>
          </Plan>
        ))}
      </OverviewMobileCon>
    </div>
  );
};

export default TabContent;
