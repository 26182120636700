import React from "react";
import {
  WhySpowseContent,
  WhySpowseLeftSection,
  WhySpowseRightSection,
  WhySpowseWrapper,
} from "./why_spowse";
import { SpowseImage } from "../../../../core/common/reuseables/spowse_image";
import { AppImages } from "../../../../core/utils/app_images";
import {
  CommonText,
  Heading1,
  Heading3,
  SpanText,
} from "../../../../core/common/reuseables/text_styles";
import { SpowseButton } from "../../../../core/common/reuseables/spowse_button";
import { theme } from "../../../../theme/theme";
import { Link } from "react-router-dom";

type WhySpowseProps = {};

const WhySpowse: React.FC<WhySpowseProps> = () => {
  return (
    <WhySpowseWrapper>
      <WhySpowseContent>
        <WhySpowseLeftSection>
          <SpowseImage src={AppImages.whySpowse} alt="img" />
        </WhySpowseLeftSection>
        <WhySpowseRightSection>
          <Heading3
            color={`${theme.colors.lightGreen}`}
            fontSize="16px"
            fs="12px"
            lh="18px"
            lineHeight="19.92px"
            letterSpacing="0.8px"
            fontWeight="700"
          >
            WHY SPOWSE, YOU ASK?
          </Heading3>
          <Heading1
            color={`${theme.colors.white}`}
            fontSize="50px"
            lineHeight="62.252px"
            letterSpacing="0.8px"
            fs="28px"
            lh="35px"
            margin="8px 0 10px 0"
            m="8px 0 16px 0"
          >
            We Are The Link To Your Roots
          </Heading1>
          <CommonText
            color={`${theme.colors.white}`}
            fontSize="16px"
            lineHeight="20px"
            fontWeight="400"
          >
            Spowse is{" "}
            <SpanText
              color={`${theme.colors.lightGreen}`}
              fontSize="16px"
              lineHeight="20px"
              fontWeight="400"
            >
              committed
            </SpanText>{" "}
            to creating a safe and empowering space for Africans{" "}
            <SpanText
              color={`${theme.colors.lightGreen}`}
              fontSize="16px"
              lineHeight="20px"
              fontWeight="400"
            >
              to
            </SpanText>{" "}
            connect, find love that feels familiar and build meaningful
            relationships. With every connection, we bring{" "}
            <SpanText
              color={`${theme.colors.lightGreen}`}
              fontSize="16px"
              lineHeight="20px"
              fontWeight="400"
            >
              Africans
            </SpanText>{" "}
            closer to the love that feels like home. Join Spowse and start your
            journey to finding love, one connection at a time.
          </CommonText>
          <Link
            to="/about"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <SpowseButton
              border="0"
              width="193px"
              height="52px"
              backgroundColor={`${theme.colors.white}`}
              textColor={`${theme.colors.primary}`}
              margin="24px 0"
            >
              Discover Our Story
            </SpowseButton>
          </Link>
        </WhySpowseRightSection>
      </WhySpowseContent>
    </WhySpowseWrapper>
  );
};
export default WhySpowse;
