import { Container, Li, List, Wrapper } from "./Content_styles";
import {
  CommonText,
  Heading2,
  Heading3,
  SpanText,
} from "../../../../core/common/reuseables/text_styles";
import { GoDotFill } from "react-icons/go";
import { theme } from "../../../../theme/theme";

const Content = () => {
  return (
    <Wrapper>
      <Container>
        <Heading2
          fontSize="32px"
          lineHeight="40px"
          color={`${theme.colors.primary}`}
          letterSpacing="0.8px"
          fontWeight="800"
          fs="24px"
          lh="30px"
        >
          Community Guidelines
        </Heading2>
        <CommonText margin="2rem 0 3rem 0">
          Great to have you here! <br /> <br />
          We want to be part of your love story but we also want Spowse to be a
          safe space for everyone. <br /> <br />
          In ensuring a conducive space for love in all its manifestations to
          thrive, we encourage you to be kind and respectful in your
          interactions. <br /> <br />
          Here are a few rules of engagement you might want to consider while
          using Spowse:
        </CommonText>
        <List>
          <Li>
            <GoDotFill style={{ fontSize: "12px" }} />
            <Heading3 fs="18px" lh="23px"> Mutual Respect</Heading3>{" "}
          </Li>
          <CommonText>
            We expect every interaction on Spowse to be based on mutual respect
            regardless of sexual orientation, race, occupation or age etc. Don’t
            use slurs or curse words on the platform. If you’re found guilty,
            your account will be flagged.
          </CommonText>
          <Li>
            <GoDotFill style={{ fontSize: "12px" }} />
            <Heading3 fs="18px" lh="23px">Consent</Heading3>{" "}
          </Li>
          <CommonText>
            Sexual harassment is highly prohibited on Spowse. Always seek
            consent before sending your steamy or NSFW photos. Reported cases
            will lead to sanctions for the guilty party. <br /> <br />
            You’re encouraged to report any case of harassment either online or
            offline (from someone you met on Spowse), we’ll make sure to address
            them.
          </CommonText>
          <Li>
            <GoDotFill style={{ fontSize: "12px" }} />
            <Heading3 fs="18px" lh="23px">Authenticity</Heading3>{" "}
          </Li>
          <CommonText>
            We don’t tolerate fake profiles or catfishing. Accounts found guilty
            of this will be deleted immediately and blacklisted. Scamming,
            solicitation, spam are highly frowned upon
          </CommonText>
          <Li>
            <GoDotFill style={{ fontSize: "12px" }} />
            <Heading3 fs="18px" lh="23px">No Hookups</Heading3>{" "}
          </Li>
          <CommonText>
            Hookups, selling nudes, porn, revenge porn are highly prohibited on
            Spowse. Any account found doing guilty will be banned and
            blacklisted.
          </CommonText>
        </List>
        <br /> <br />
      </Container>
    </Wrapper>
  );
};

export default Content;
