export const plans = [
  {
    image: "/assets/images/boostt.svg",
    title: "Boost",
    desc: "Gain a temporary advantage to boost your experience",
    prices: {
      weekly: "$0.61",
      monthly: "$1.93",
    },
    period: "Per week",
    keyFeature: "Key features:",
    features: ["Spotlight", "Extended video call", "Profile backtracking"],
    backgroundColor: "#A4BDC6",
  },
  {
    image: "/assets/images/premiumm.svg",
    title: "Premium",
    desc: "Enjoy more additional and personalized features.",
    prices: {
      weekly: "$1.67",
      monthly: "$6.52",
    },
    period: "Per week",
    keyFeature: "All boost features, plus:",
    features: [
      "Additional nova likes",
      "Additional daily slides",
      "See who liked your profile",
      "See who saved your profile",
      "Access to Cupid time",
    ],
    backgroundColor: "#CEF4DB",
  },
  {
    image: "/assets/images/platinumm.svg",
    title: "Platinum",
    desc: "Get unlimited access to every exciting feature on Spowse.",
    prices: {
      weekly: "$2.22",
      monthly: "$8.27",
    },
    period: "Per week",
    keyFeature: "All premium features, plus:",
    features: [
      "Increased nova likes",
      "Increased daily slides",
      "Snooze mode",
      "Super nova likes",
      "Instant messaging",
      "Access to VIP Profiles",
      "Access to Cupid time",
    ],
    backgroundColor: "#FFE499",
  },
];

export const plansOverwiew = [
  {
    features: "Spotlight",
    free: "",
    boost: "/assets/icons/boostdot.svg",
    premium: "/assets/icons/premiumdot.svg",
    platinum: "/assets/icons/platinumdot.svg",
  },
  {
    features: "Extended Video Call",
    free: "",
    boost: "/assets/icons/boostdot.svg",
    premium: "/assets/icons/premiumdot.svg",
    platinum: "/assets/icons/platinumdot.svg",
  },
  {
    features: "Profile Backtracking",
    free: "",
    boost: "/assets/icons/boostdot.svg",
    premium: "/assets/icons/premiumdot.svg",
    platinum: "/assets/icons/platinumdot.svg",
  },
  {
    features: "Nova Likes",
    free: "/assets/icons/freedot.svg",
    boost: "/assets/icons/boostdot.svg",
    premium: "/assets/icons/premiumdot.svg",
    platinum: "/assets/icons/platinumdot.svg",
  },
  {
    features: "Daily Slides",
    free: "/assets/icons/freedot.svg",
    boost: "/assets/icons/boostdot.svg",
    premium: "/assets/icons/premiumdot.svg",
    platinum: "/assets/icons/platinumdot.svg",
  },
  {
    features: "See Profiles You liked",
    free: "/assets/icons/freedot.svg",
    boost: "/assets/icons/boostdot.svg",
    premium: "/assets/icons/premiumdot.svg",
    platinum: "/assets/icons/platinumdot.svg",
  },
  {
    features: "See Who Liked Your Profile",
    free: "",
    boost: "",
    premium: "/assets/icons/premiumdot.svg",
    platinum: "/assets/icons/platinumdot.svg",
  },
  {
    features: "See Profiles You Saved",
    free: "/assets/icons/freedot.svg",
    boost: "/assets/icons/boostdot.svg",
    premium: "/assets/icons/premiumdot.svg",
    platinum: "/assets/icons/platinumdot.svg",
  },
  {
    features: "See Who Saved Your Profile",
    free: "",
    boost: "",
    premium: "/assets/icons/premiumdot.svg",
    platinum: "/assets/icons/platinumdot.svg",
  },
  {
    features: "Snooze Mode",
    free: "",
    boost: "",
    premium: "",
    platinum: "/assets/icons/platinumdot.svg",
  },
  {
    features: "Supernova Likes",
    free: "",
    boost: "",
    premium: "",
    platinum: "/assets/icons/platinumdot.svg",
  },
  {
    features: "Instant Messaging",
    free: "",
    boost: "",
    premium: "",
    platinum: "/assets/icons/platinumdot.svg",
  },
  {
    features: "Access To VIP profiles",
    free: "",
    boost: "",
    premium: "",
    platinum: "/assets/icons/platinumdot.svg",
  },
  {
    features: "Access To Cupid Time",
    free: "",
    boost: "",
    premium: "/assets/icons/premiumdot.svg",
    platinum: "/assets/icons/platinumdot.svg",
  },
];

export const overview = [
  {
    title: "Free plan",
    features: [
      "Nova likes",
      "Daily slides",
      "See profile you liked",
      "See profile you saved",
    ],
  },
  {
    title: "Boost plan",
    features: [
      "Spotlight",
      "Extended video call",
      "Profile backtracking",
      "Nova likes",
      "Daily slides",
      "See profile you liked",
      "See profile you saved",
    ],
  },
  {
    title: "Spowse Premium",
    features: [
      "Spotlight",
      "Extended video call",
      "Profile backtracking",
      "Nova likes",
      "Daily slides",
      "See profile you liked",
      "See who liked your profile",
      "See profile you saved",
      "See who saved your profile",
      "Access to Cupid Time",
    ],
  },
  {
    title: "Spowse Platinum",
    features: [
     "Spotlight",
      "Extended video call",
      "Profile backtracking",
      "Nova likes",
      "Daily slides",
      "See profile you liked",
      "See who liked your profile",
      "See profile you saved",
      "See who saved your profile",
      "Snooze mode",
      "Super nova likes",
      "Instant messaging",
      "Access to VIP Profiles",
      "Access to Cupid Time",
    ],
  },
];
