import styled from "styled-components";
import { theme } from "../../theme/theme";
import { Link } from "react-router-dom";

interface HeaderProbs {
  active?: any;
}

export const LogoClose = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 1.5rem;
`;

export const NavLink = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 1.5rem; */
  margin: 0 1.5rem;

`;

export const CustomLink = styled(Link)<HeaderProbs>`
  font-size: 20px;
  color: ${(probs) =>
    probs.active ? theme.colors.secondary : `${theme.colors.primary}`};
  text-decoration: none;
  font-family:"Kumbh Sans", sans-serif;
  letter-spacing: 1.2px;
  font-weight: 700;
  line-height: 25px;
  border-top: 1px solid #cbd1ce;
  padding: 1.5rem 0;

  &:last-child {
    border-bottom: 1px solid #cbd1ce;
  }

  &:hover {
    color: ${theme.colors.secondary};
    transform: scale(1.1);
    transition: 300ms all ease-in;
  }
`;

export const Bottom = styled.div`
    background-color: ${theme.colors.bgPrimary};
    padding: 2rem 0;
    position: fixed;
    bottom: 0;
    width: 100%;
`;

export const BtnCon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
`

export const Socials = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 22px;
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    padding: 1rem 1.5rem;

`

export const SocialLink = styled.a`
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: 1.5px solid ${theme.colors.white};
    padding: 13px;
    text-decoration: none;
`