import React from "react";
import {
  Button,
  FooterBottomSection,
  FooterContent,
  FooterIconContainer,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterTopSection,
  FooterWrapper,
  LogoButtonContainer,
  UpperBtn,
  UpperSocials,
} from "./Footer_styles";
import { SpowseImage } from "../../core/common/reuseables/spowse_image";
import { AppIcons } from "../../core/utils/app_icons";
import { theme } from "../../theme/theme";
import { footerLinks } from "./controller/logic";
import { Heading2, LinkText } from "../../core/common/reuseables/text_styles";
import { Divider } from "../../core/common/reuseables/Divider";
import { Link } from "react-router-dom";

type FooterProps = {};

const Footer: React.FC<FooterProps> = () => {
  return (
    <FooterWrapper>
      <FooterContent>
        <Link
          to="https://play.google.com/store/apps/details?id=io.spowse.androidApp"
          style={{ textDecoration: "none", color: "inherit" }}
          target="_blank"
        >
          <UpperBtn>Download The App</UpperBtn>
        </Link>
        <UpperSocials>
          <FooterIconContainer href="https://x.com/spowseofficial/">
            <SpowseImage src={AppIcons.twitter} alt="icon" />
          </FooterIconContainer>
          <FooterIconContainer href="https://www.facebook.com/spowse.io">
            <SpowseImage src={AppIcons.facebook} alt="icon" />
          </FooterIconContainer>
          <FooterIconContainer href="https://www.instagram.com/spowseofficial">
            <SpowseImage src={AppIcons.instagram} alt="icon" />
          </FooterIconContainer>
          <FooterIconContainer href="https://youtube.com/@spowse">
            <SpowseImage src={AppIcons.youtube} alt="icon" />
          </FooterIconContainer>
          <FooterIconContainer href="https://www.linkedin.com/company/spowse/">
            <SpowseImage src={AppIcons.linkedIn} alt="icon" />
          </FooterIconContainer>
        </UpperSocials>
        <FooterTopSection>
          <LogoButtonContainer>
          <Link
            to="/"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <SpowseImage
              src={AppIcons.spowseWhiteLogo}
              alt="logo"
              width="105px"
              height="20px"
            />
            </Link>
            <Link
              to="https://play.google.com/store/apps/details?id=io.spowse.androidApp"
              style={{ textDecoration: "none", color: "inherit" }}
              target="_blank"
            >
              <Button>Download The App</Button>
            </Link>
          </LogoButtonContainer>
          <FooterLinksContainer>
            {footerLinks.map((link, index) => {
              return (
                <FooterLinksWrapper key={index}>
                  <Heading2
                    color={`${theme.colors.white}`}
                    fontSize="26px"
                    letterSpacing="0.8px"
                    lineHeight="32.37px"
                    fs="18px"
                    lh="24px"
                  >
                    {link.topText}
                  </Heading2>
                  {link.links.map((l, index) => {
                    return (
                      <LinkText
                        to={l.to}
                        key={index}
                        color={`${theme.colors.offWhite}`}
                        fontSize="16px"
                        fontWeight="400"
                        letterSpacing="0.8px"
                        lineHeight="19.2px"
                      >
                        {l.value}
                      </LinkText>
                    );
                  })}
                </FooterLinksWrapper>
              );
            })}
          </FooterLinksContainer>
        </FooterTopSection>
        <Divider width="100%" height="1px" spacing="3rem" display="none" />
        <FooterBottomSection>
          <FooterIconContainer href="https://x.com/spowseofficial/">
            <SpowseImage src={AppIcons.twitter} alt="icon" />
          </FooterIconContainer>
          <FooterIconContainer href="https://www.facebook.com/spowse.io">
            <SpowseImage src={AppIcons.facebook} alt="icon" />
          </FooterIconContainer>
          <FooterIconContainer href="https://www.instagram.com/spowseofficial">
            <SpowseImage src={AppIcons.instagram} alt="icon" />
          </FooterIconContainer>
          <FooterIconContainer href="https://youtube.com/@spowse">
            <SpowseImage src={AppIcons.youtube} alt="icon" />
          </FooterIconContainer>
          <FooterIconContainer href="https://www.linkedin.com/company/spowse/">
            <SpowseImage src={AppIcons.linkedIn} alt="icon" />
          </FooterIconContainer>
        </FooterBottomSection>
      </FooterContent>
    </FooterWrapper>
  );
};
export default Footer;
