import React, { useState } from "react";
import {
  CommonText,
  Heading1,
  Heading3,
} from "../../../../core/common/reuseables/text_styles";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Divider } from "../../../../core/common/reuseables/Divider";
import { theme } from "../../../../theme/theme";
import { FaqCon, Faqq, FaqSectionCon, Question, Wrapper } from "./styles";
import { faqs } from "../../../help-and-support/data/data";
import "../../index.css"

const Faq = () => {
  const [selected, setSelected] = useState(null);

  const toggle = (index: any) => {
    if (selected === index) {
      return setSelected(null);
    }
    setSelected(index);
  };
  return (
    <Wrapper>
      <FaqSectionCon>
        <Heading3
          color={`${theme.colors.secondary}`}
          fontWeight="800"
          fontSize="16px"
          lineHeight="20px"
          letterSpacing="0.8px"
          fs=" 12px"
          lh="15px"
          textAlign="center"
        >
          FAQS
        </Heading3>
        <Heading1
          color={`${theme.colors.primary}`}
          fontWeight="800"
          fontSize="50px"
          lineHeight="63px"
          letterSpacing="0.8px"
          fs="30px"
          lh="35px"
          margin="0.5rem 0 1rem"
          textAlign="center"
        >
          Frequently Asked Questions
        </Heading1>
        <CommonText
          color={`${theme.colors.primary}`}
          fontWeight="400"
          fontSize="16px"
          lineHeight="20px"
          letterSpacing="0.8px"
          textAlign="center"
          width="600px"
          w="100%"
          margin="0 auto"
        >
          Your answer to all the questions you could have about Spowse.
        </CommonText>
        <FaqCon>
          {faqs.map((item, index) => (
            <Faqq key={index} onClick={() => toggle(index)}>
              <Divider
                height="1px"
                width="100%"
                backgroundColor="#B5BDBA"
                spacing="1.5rem 0"
              />
              <Question>
                <Heading1
                  color={`${theme.colors.primary}`}
     fontWeight="800"
                  fontSize="24px"
                  lineHeight="30px"
                  letterSpacing="0.8px"
                  fs="18px"
                  lh="23px"
                  margin="0"
                >
                  {item?.question}
                </Heading1>
                {selected === index ? (
                  <IoIosArrowUp className="icon"/>
                ) : (
                  <IoIosArrowDown className="icon" />
                )}
              </Question>
              <div className={selected === index ? "show" : "hide"}>
              <CommonText margin="1rem 0">{item?.ans1}</CommonText>
                <CommonText margin=" 0 0 1rem 0">{item?.ans2}</CommonText>
                {item.ans3 && (
                  <CommonText margin=" 0 0 1rem 0">{item?.ans3}</CommonText>
                )}
                {item.ans4 && (
                  <CommonText margin=" 0 0 1rem 0">{item?.ans4}</CommonText>
                )}
              {item.ans5 && (
                  <CommonText margin=" 0 0 1rem 0">{item?.ans5}</CommonText>
                )}
              </div>
            </Faqq>
          ))}
          <Divider
            height="1px"
            width="100%"
            backgroundColor="#B5BDBA"
            spacing="1.5rem 0 0 0"
          />
        </FaqCon>
      </FaqSectionCon>
    </Wrapper>
  );
};

export default Faq;
