import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import {
  FadeInTextContainer,
  ImageSection,
  HeroWrapper,
  HeroContent,
  MiddleContext,
  PaginationContainer,
  ActiveContainner,
} from "./Hero_styles";
import {
  CommonText,
  Heading1,
} from "../../../../core/common/reuseables/text_styles";
import { theme } from "../../../../theme/theme";
import { SpowseButton } from "../../../../core/common/reuseables/spowse_button";
import { Link } from "react-router-dom";

const Hero: React.FC = () => {
  const desktopBgImages = [
    "https://res.cloudinary.com/dsjgyouib/image/upload/v1733344367/website%20images/pempwk6inrmomui2aplf.png",
    "https://res.cloudinary.com/dsjgyouib/image/upload/v1733344363/website%20images/gphv9cqmg94eeiasrqpt.png",
    "https://res.cloudinary.com/dsjgyouib/image/upload/v1733344363/website%20images/t8dvcmujoskcz0skutyz.png",
  ];

  const mobileBgImages = [
    "https://res.cloudinary.com/dsjgyouib/image/upload/v1733344352/website%20images/ayhqkg9ne4jtdidbemwr.png",
    "https://res.cloudinary.com/dsjgyouib/image/upload/v1733344352/website%20images/tchgvyer9xnpitxpx6q4.png",
    "https://res.cloudinary.com/dsjgyouib/image/upload/v1733344353/website%20images/lbjiqguvdczrrpnx40jd.png",
  ];

  const tabImgs = [
    "https://res.cloudinary.com/dsjgyouib/image/upload/v1733344363/website%20images/yanfsheyzlec4bmegfj2.png",
    "https://res.cloudinary.com/dsjgyouib/image/upload/v1733344363/website%20images/sb6vgw9dejjxzyvqmoou.png",
    "https://res.cloudinary.com/dsjgyouib/image/upload/v1733344365/website%20images/f3k3gaakojezcmcmrgnd.png",
  ];

  const texts = [
    "The App Where Every African Heart Finds Home",
    "More Than Slides, Find Your Ideal Partner", 
    "Your Forever Starts Here. Love, Spowse",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % desktopBgImages.length);
    }, 6000);

    return () => clearInterval(intervalId);
  }, [desktopBgImages.length]);

  return (
    <HeroWrapper>
      <HeroContent>
        <AnimatePresence>
          <ImageSection
            key={currentIndex}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 1.3,
              ease: "easeInOut",
            }}
            backgroundImage={`url(${desktopBgImages[currentIndex]})`}
            bgImage={`url(${mobileBgImages[currentIndex]})`}
            tabletImg={`url(${tabImgs[currentIndex]})`}
          />
        </AnimatePresence>
        {/* fade text section */}

        <FadeInTextContainer>
          <MiddleContext>
            <motion.div
              key={currentIndex}
              initial={{ opacity: 0, y: 80 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -80 }}
              transition={{
                duration: 1.3,
                ease: "easeInOut",
              }}
            >
              <Heading1
                fontSize="70px"
                fontFamily='"Kumbh Sans", sans-serif'
                fontWeight="800"
                letterSpacing="0.8px"
                lineHeight="88px"
                color={`${theme.colors.white}`}
                textAlign="center"
                fs="35px"
                lh="44px"
                width="1000px"
                contentSize="100%"
                w="100%"
              >
                {texts[currentIndex]}
              </Heading1>
            </motion.div>
            <CommonText
              fontSize="18px"
              fs="16px"
              fontFamily="geist-regular"
              fontWeight="400"
              color="rgba(242, 242, 242, 1)"
              letterSpacing="0.2px"
              textAlign="center"
              width="55%"
              w="100%"
              wid="80%"
              margin="3px 0 26px 0"
            >
              Forge meaningful connections and lasting relationships in a
              community of African singles who value commitment like you do.
            </CommonText>

            <Link
              to="https://play.google.com/store/apps/details?id=io.spowse.androidApp"
              style={{ textDecoration: "none", color: "inherit" }}
              target="_blank"
            >
              <SpowseButton
                width="198px"
                height="52px"
                backgroundColor="transparent"
                bg="#ffffff"
                color="#10281E"
              >
                Download The App
              </SpowseButton>
            </Link>
          </MiddleContext>
        </FadeInTextContainer>
        <PaginationContainer>
          {texts.map((e, index) => {
            return (
              <ActiveContainner
                key={e}
                activeIndex={currentIndex}
                index={index}
              ></ActiveContainner>
            );
          })}
        </PaginationContainer>
      </HeroContent>
    </HeroWrapper>
  );
};

export default Hero;
