import React, { useEffect, useState } from "react";
import {
  Categories,
  Container,
  Wrapper,
  EactCat,
  GridCon,
  GridItems,
  ImageCon,
} from "./Grid_styles";
import { categories, postss } from "../../data/data";
import { SpowseImage } from "../../../../core/common/reuseables/spowse_image";
import {
  CommonText,
  Heading1,
  Heading3,
} from "../../../../core/common/reuseables/text_styles";
import { theme } from "../../../../theme/theme";
import { Link } from "react-router-dom";
import "./index.css";
import BlogService from "../../../../core/api/services/blog";
import { formatDate } from "../../../../utilis/date";
import Loader from "../../../loader/Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

interface BlogPost {
  title: string;
  category: string;
  publishDate: string;
  thumbnail: string;
  id: string;
}

const Grid = () => {
  const [activeTab, setActiveTab] = useState<string>("All");
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const handleTabClick = async (category: string) => {
    setActiveTab(category);
    setLoading(true);

    const response = await BlogService.getBlogsByCategory(
      category !== "All" ? category : undefined
    );
    if (response.success) {
      setPosts(response.blogs || []);
    } else {
      setError(response.message || "Failed to load blogs");
    }
    setLoading(false);
  };

  useEffect(() => {
    handleTabClick("All");
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <Wrapper>
      <Container>
        <Categories className="categories">
          {categories.map((item, index) => (
            <EactCat
              key={index}
              active={item.cat === activeTab}
              onClick={() => handleTabClick(item.cat)}
            >
              {item.cat}
            </EactCat>
          ))}
        </Categories>
        <GridCon>
          {posts.map((blog, index) => {
            return (
              <Link to={`/trybe/${blog.id}`} style={{ textDecoration: "none" }}>
                <GridItems key={index}>
                  <ImageCon>
                    <LazyLoadImage src={blog?.thumbnail} width="100%" effect="blur"/>
                  </ImageCon>
                  <Heading3
                    color={`${theme.colors.secondary}`}
                    fontSize="14px"
       fontWeight="800"
                    fs="12px"
                    lh="15px"
                    lineHeight="18px"
                    letterSpacing="0.8px"
                    margin="24px 0 8px 0"
                  >
                    {blog?.category}
                  </Heading3>
                  <Heading1
                    color={`${theme.colors.primary}`}
                    fontSize="24px"
       fontWeight="800"
                    fs="20px"
                    lh="25px"
                    lineHeight="30px"
                    letterSpacing="0.8px"
                    margin="0 0 16px 0"
                  >
                    {blog?.title}{" "}
                  </Heading1>
                  <CommonText
                    color="#282D2B"
                    fontSize="14px"
                    fontWeight="400"
                    fs="14px"
                    lh="15px"
                    lineHeight="18px"
                    letterSpacing="0.8px"
                    margin="0"
                  >
                    {formatDate(blog?.publishDate)}
                  </CommonText>
                </GridItems>
              </Link>
            );
          })}
        </GridCon>
      </Container>
    </Wrapper>
  );
};

export default Grid;
