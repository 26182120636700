import React from "react";
import { Container, Wrapper } from "./Cta_styles";
import {
  CommonText,
  Heading1,
  Heading3,
  Heading4,
  SpanText,
} from "../../../../core/common/reuseables/text_styles";
import { theme } from "../../../../theme/theme";

const Cta = () => {
  return (
    <Wrapper>
      <Container>
        <Heading3
          textAlign="center"
          color={`${theme.colors.secondary}`}
          fontWeight="800"
          fontSize="16px"
          lineHeight="20px"
          letterSpacing="0.8px"
          fs=" 12px"
          lh="15px"
        >
          NEED A HAND?
        </Heading3>
        <Heading1
          color={`${theme.colors.primary}`}
          fontWeight="800"
          fontSize="50px"
          lineHeight="63px"
          letterSpacing="0.8px"
          fs="28px"
          lh="35px"
          margin="0.7rem 0"
          textAlign="center"
        >
          We’re Here! Contact Us
        </Heading1>
        <CommonText
          color={`${theme.colors.primary}`}
          fontWeight="400"
          fontSize="16px"
          lineHeight="20px"
          letterSpacing="0.8px"
          textAlign="center"
        >
          Reach out to us on any of these platforms and we will definitely get
          back to you
        </CommonText>
        <Heading4
          fontSize="18px"
          lineHeight="23px"
          textAlign="center"
          letterSpacing="0.8px"
          fontWeight="800"
          fs="16px"
          lh="20px"
          margin="2rem 0"
        >
          Reach us on Email |{" "}
          <a
            href="mailto:hello@spowse.io"
            style={{ textDecoration: "none", color: "#099838" }}
          >
            <SpanText
              color={`${theme.colors.secondary}`}
              fontSize="18px"
              fontWeight="400"
              fontFamily="geist-regular"
              lineHeight="23px"
              letterSpacing="0.8px"
              fs="16px"
              lh="20px"
            >
              {" "}
              hello@spowse.io
            </SpanText>{" "}
          </a>
        </Heading4>
        <Heading4
          fontSize="18px"
          lineHeight="23px"
          textAlign="center"
          letterSpacing="0.8px"
          fontWeight="800"
          fs="16px"
          lh="20px"
          margin="1rem 0"
        >
          Send us your requests |
          <a
            href="/contact-us/submit-a-request"
            style={{ textDecoration: "none", color: "#099838" }}
          >
            <SpanText
              color={`${theme.colors.secondary}`}
              fontSize="18px"
              fontWeight="400"
              fontFamily="geist-regular"
              lineHeight="23px"
              letterSpacing="0.8px"
              fs="16px"
              lh="20px"
            >
              {" "}
              Submit a request
            </SpanText>{" "}
          </a>
        </Heading4>
      </Container>
    </Wrapper>
  );
};

export default Cta;
