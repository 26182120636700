import React from "react";
import { Container, Left, Right, Wrapper } from "./Hero_styles";
import {
  CommonText,
  Heading1,
  Heading3,
} from "../../../../core/common/reuseables/text_styles";
import { SpowseImage } from "../../../../core/common/reuseables/spowse_image";
import { AppImages } from "../../../../core/utils/app_images";
import { theme } from "../../../../theme/theme";

const Hero = () => {
  return (
    <Wrapper>
      <Container>
        <Left>
          <Heading3
            color={`${theme.colors.secondary}`}
            fontWeight="800"
            fontSize="16px"
            lineHeight="20px"
            letterSpacing="0.8px"
            fs=" 12px"
            lh="15px"
          >
            SHARE YOUR LOVE STORY
          </Heading3>
          <Heading1
            color={`${theme.colors.primary}`}
            fontWeight="800"
            fontSize="60px"
            lineHeight="75px"
            letterSpacing="0.8px"
            fs="30px"
            lh="38px"
            margin="4px 0 8px 0"
            width="80%"
            w="100%"
          >
            When did cupid’s shot hit?{" "}
          </Heading1>
          <CommonText
            color="#282D2B"
            fontWeight="400"
            fontSize="16px"
            lineHeight="20px"
            letterSpacing="0.8px"
            width="80%"
            w="100%"
          >
            Whether it’s about your first date or how you found the love of your
            life on Spowse, we’ll love to hear from you.
          </CommonText>
        </Left>
        <Right>
          <SpowseImage src={AppImages.cupid} alt="img"  borderRadius="35px" />
        </Right>
      </Container>
    </Wrapper>
  );
};

export default Hero;
