import styled from "styled-components";
import { theme } from "../../theme/theme";

export const FooterWrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.bgPrimary};
  margin-top: 100px;
  padding: 3rem 4rem;

  @media (max-width: 1100px) {
    padding: 6rem 2rem;
  }
  @media (max-width: 1100px) {
    padding: 2rem 1.5rem;
  }
`;

export const FooterContent = styled.div`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

//top section styles

export const FooterTopSection = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 4rem;
  margin-bottom: 1rem;
  @media (max-width: 991px) {
    gap: 2rem;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

export const LogoButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;

`;

export const Button = styled.button`
  width: 178px;
  height: 52px;
  font-size: 16px;
  font-weight: 400;
  color: ${theme.colors.primary};
  border: 0;
  background-color: ${theme.colors.lightGreen};
  border-radius: 30px;

  @media (max-width: 900px) {
    display: none;
  }
`;
export const FooterLinksContainer = styled.div`
  /* width: 70%; */
  flex: 4;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
    width: 100%;
    row-gap: 4rem;
  }
`;

export const FooterLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.5rem;
  @media (max-width: 900px) {
    gap:16px;
  }
`;

//bottom section styles

export const FooterBottomSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  @media (max-width: 900px) {
    display: none;
  }
`;

export const FooterIconContainer = styled.a`
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 1.5px solid ${theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;

  & > :nth-child(1) {
    width: 40%;
  }
`;

export const UpperBtn = styled.button`
  display: none;

  @media (max-width: 900px) {
    display: block;
    font-size: 16px;
    width: 270px;
    height: 52px;
    font-weight: 400;
    margin-bottom: 1.5rem;
    color: ${theme.colors.primary};
    border: 1px solid white;
    background-color: ${theme.colors.lightGreen};
    border-radius: 30px;
    padding: 12px 30px;
  }
`;

export const UpperSocials = styled.div`
display: none;
  @media (max-width: 900px) {
    display: flex;
    gap: 20px;
    margin: 0 0 2rem 0;
    border-top: 1px  solid white;
    border-bottom: 1px solid white ;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
  }
`;
