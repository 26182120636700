import styled from "styled-components";
import { theme } from "../../../../theme/theme";

export const Wrapper = styled.section`
  width: 100%;
  margin: 1.5rem 0 5rem 0;  
  background-color: ${theme.colors.bgPrimary};
`;

export const Container = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  padding: 8rem 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 128px;
  @media (max-width: 1000px) {
    gap: 40px;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    gap: 4rem;
    padding: 4rem 1.5rem;
  }
`;

export const Left = styled.div`
  flex: 1;
  margin-right: 2rem;
`;

export const Right = styled.div`
  flex: 1;
  font-family: "geist-light";
  letter-spacing: 0.8px;
  margin-left: 1rem;
  @media (max-width: 800px) {
    margin-left: 0rem;
  }
`;

export const SmallText = styled.p`
  color: ${theme.colors.lightGreen};
  font-weight: 800;
  font-size: 16px;
  font-family: "Kumbh Sans", sans-serif;
  letter-spacing: 0.8px;
  @media (max-width: 800px) {
    font-size: 12px;
  }
`;
export const TextCon = styled.div`
  border-left: 3px solid ${theme.colors.lightGreen};
  padding-left: 1.5rem;
  margin: 0.5rem 0;
  @media (max-width: 800px) {
   padding-left: 1rem;
  }
`;

export const BigText = styled.h1`
  color: ${theme.colors.white};
  font-size: 40px;
  line-height: 52px;
  font-family: "Kumbh Sans", sans-serif;
  letter-spacing: 0.8px;
  @media (max-width: 800px) {
    font-size: 24px;
    line-height: normal;
  }
`;

export const SpanBig = styled.span`
  color: ${theme.colors.lightGreen};
  font-family: "Kumbh Sans", sans-serif;
  letter-spacing: 0.8px;
`;

export const Span = styled.span`
  color: ${theme.colors.lightGreen};
  font-family: "geist-light";
  letter-spacing: 0.8px;
`;

export const Desc = styled.p`
  color: ${theme.colors.white};
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 1.5rem;
  font-family: "geist-light";
  font-weight: 400;
  @media (max-width: 800px) {
    margin-bottom: 1rem;
  }
`;
