import {
  BtnCon,
  BtnConBig,
  Container,
  Form,
  FormContainer,
  FormSection,
  GridCon,
  GridItems,
  ImgCon,
  Input,
  InputCon,
  Wrapper,
} from "./Related_post_styles";
import {
  CommonText,
  Heading1,
  Heading3,
  Heading4,
} from "../../../../core/common/reuseables/text_styles";
import { Divider } from "../../../../core/common/reuseables/Divider";
import { relatedArticles } from "../../data/data";
import { SpowseImage } from "../../../../core/common/reuseables/spowse_image";
import { theme } from "../../../../theme/theme";
import { SpowseButton } from "../../../../core/common/reuseables/spowse_button";
import { FaCheck } from "react-icons/fa6";
import { Link } from "react-router-dom";

const RelatedPost = () => {
  return (
    <Wrapper>
      <Container>
        <Divider
          width="100%"
          height="1px"
          backgroundColor="#B5BDBA"
          spacing="0 0 5rem 0"
          m="0 0 3rem 0"
        ></Divider>
        <Heading4>Related Articles</Heading4>
        <GridCon>
          {relatedArticles.map((item, index) => (
            <Link to={`/trybe/${item.id}`} style={{ textDecoration: "none" }}>
            <GridItems key={index}>
              <ImgCon>
                <SpowseImage src={item?.image} />
              </ImgCon>
              <Heading3
                color={`${theme.colors.secondary}`}
                fontSize="14px"
   fontWeight="800"
                fs="12px"
                lh="15px"
                lineHeight="18px"
                letterSpacing="0.8px"
                margin="1rem 0 10px 0"
              >
                {" "}
                {item?.category}{" "}
              </Heading3>
              <Heading1
                color={`${theme.colors.primary}`}
                fontSize="24px"
                fontWeight="800"
                fs="20px"
                lh="25px"
                lineHeight="30px"
                letterSpacing="0.8px"
                margin=" 0"
              >
                {item?.title}{" "}
              </Heading1>
              <CommonText
                fontSize="14px"
                lineHeight="17px"
                fs="14px"
                lh="17px"
                margin="14px 0"
              >
                {item?.date}
              </CommonText>
            </GridItems>
            </Link>
          ))}
        </GridCon>
        <FormSection>
          <FormContainer>
            <Heading3
              color={`${theme.colors.secondary}`}
              fontSize="12px"
              fontWeight="800"
              lineHeight="15px"
              letterSpacing="0.8px"
            >
              NEWSLETTERS
            </Heading3>
            <Heading1
              color={`${theme.colors.primary}`}
              fontSize="24px"
              fontWeight="800"
              fs="20px"
              lh="25px"
              lineHeight="30px"
              letterSpacing="0.8px"
              margin="0 auto"
              textAlign="center"
              width="63%"
              w="100%"
            >
              Weekly inspirations, guidelines, insights and dating advice for
              your inbox.
            </Heading1>
            <Form>
              <InputCon>
                <Input placeholder="Enter your email address" type="email" />
                <BtnConBig>
                  <SpowseButton>Subscribe</SpowseButton>
                </BtnConBig>
                <BtnCon>
                  <SpowseButton width="100%" borderRadius="50%" padding="15px">
                    <FaCheck style={{ fontSize: "20px" }} />
                  </SpowseButton>
                </BtnCon>
              </InputCon>
            </Form>
          </FormContainer>
        </FormSection>
      </Container>
    </Wrapper>
  );
};

export default RelatedPost;
